import { reactive, onMounted } from 'vue';
import { required, numeric } from '@vuelidate/validators';
import { useRouter } from 'vue-router';

import { createTask } from '@/api/task';
import { getManufactureList } from '@/api/manufacture';
import { getProducts } from '@/api/product';
import { getAllStock } from '@/api/stock';
import { errorAlert } from '@/helpers/alerts';
import { getMeasurement } from '@/api/measurement';

export default function useTask() {
  const router = useRouter();
  const state = reactive({
    productOptionsList: [],
    stockOptionsList: [],
    manufactureOptionsList: [],
    measurementOptionsList: [],
  });

  onMounted(async () => {
    try {
      state.manufactureOptionsList = await getManufactureList();
    } catch {
      errorAlert('Ошибка сервера!');
    }
  });

  onMounted(async () => {
    try {
      state.productOptionsList = await getProducts();
    } catch {
      errorAlert('Ошибка сервера!');
    }
  });

  onMounted(async () => {
    try {
      state.stockOptionsList = await getAllStock();
    } catch {
      errorAlert('Ошибка сервера!');
    }
  });

  onMounted(async () => {
    try {
      state.measurementOptionsList = await getMeasurement();
    } catch {
      errorAlert('Ошибка сервера!');
    }
  });

  const redirectToTask = (response) => {
    router.push({
      name: 'formation-page',
      params: {
        id: response.id,
      },
    });
  };

  const validation = {
    product: { required },
    quantity: { required, numeric, minValue: (item) => item >= 1 },
    manufacture: { required },
    area: {},
    recivier_manufacture: {},
    recivier_area: {},
    stock: {},
    users: { required },
    need_time: { required },
    measurement: { required },
  };

  return { taskState: state, redirectToTask, createTask, validation };
}
