<template>
  <div class="depot__search-wrapper">
    <inline-svg
      class="depot__search-icon"
      :src="require('@/assets/images/search.svg')"
      fill="#000"
    />

    <input
      type="text"
      class="depot__search"
      placeholder="Поиск"
      v-model="state.searchQuery"
      @focus="state.isSearchFocused = true"
      @blur="state.isSearchFocused = false"
      @change="updateSearchFilter"
    />

    <button type="button" class="depot__search-reset" @mousedown="resetSearch">
      <inline-svg :src="require('@/assets/images/close.svg')" fill="#D9D9D9" />
    </button>

    <ul
      class="depot__search-results"
      :class="{
        isSearch:
          state.searchResults.length > 0 &&
          state.isSearchFocused &&
          state.searchQuery?.length,
      }"
    >
      <li
        v-for="result in state.searchResults"
        :key="result.id"
        @mousedown="
          state.searchQuery = result.title;
          updateSearchFilter();
        "
      >
        {{ result.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, toRef, watch } from "vue";

export default {
  props: ["modelValue"],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const searchFilter = toRef(props, "modelValue");
    const state = reactive({
      searchQuery: null,
      searchResults: [],
      isSearchFocused: false,
    });

    const resetSearch = () => {
      if (!state.searchQuery) return;

      state.searchQuery = null;
      state.searchResults = [];
      updateSearchFilter();
    };

    watch(searchFilter, () => {
      if (searchFilter.value == null) resetSearch();
    });

    // watch(
    //   () => state.searchQuery,
    //   () => {
    //     onSearch();
    //   }
    // );

    // const onSearch = () => {
    //   state.searchResults = [];

    //   if (state.searchQuery)
    //     setTimeout(() => {
    //       state.searchResults = [
    //         { id: 0, title: "Input text" },
    //         { id: 1, title: "Input text 1" },
    //       ];
    //     }, 1000);
    // };

    const updateSearchFilter = () => {
      emit("update:modelValue", state.searchQuery);
    };

    return {
      state,
      resetSearch,
      updateSearchFilter,
    };
  },
};
</script>